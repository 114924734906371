<template>
  <div class="container-fluid bg-light">
    <!-- REG HORIZON -->
    <div class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <div class="col">
        <h1 class="kstyle">About</h1>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-container>

<p>This website allows you to display content from your KPMG Regulatory Horizon API (‘API’) data subscription. You need a valid API subscription to access this website.
</p><p><strong>Warranties</strong>
</p><p>We do not warrant that operation of this website or the API will be uninterrupted or error free.
</p><p><strong>Third-party websites</strong>
</p><p>You acknowledge that this website and the API may enable or assist you to access the website content of or correspond with third parties via third-party websites and that it does so solely at your own risk.
</p><p>KPMG make no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website.  We recommend that you refer to the third party's website terms and conditions and privacy policy prior to using the relevant third-party website.
</p><p>We have no relationship, association or affiliation with any third-party websites.  We do not endorse or approve any third-party website nor the content of any of the third-party website made available via the API.
</p><p><strong>Your Obligations</strong>
</p><p>You must put in place and maintain adequate security measures to safeguard the API from unauthorised access, use or copying.
</p><p>You are responsible for ensuring that your use of the API complies with any applicable legislation or other legal requirements.
</p><p>You are solely responsible for providing the means by which you access and use the API.
</p><p>You are responsible for making any notifications, registrations and disclosures required of you by law or regulation.
</p><p>You are responsible for:
<ul>
<li>the management, conduct and operation of your business, interests and affairs;
</li><li>compliance with your regulatory obligations;
</li><li>deciding how you configure your systems to extract the data from the API;
</li><li>deciding whether to visit third party websites; and
</li><li>the delivery, achievement or realisation of any benefits directly or indirectly related to the API.</li>
</ul>
</p><p>Any opinion, statement of expectation, forecast or recommendation that you derive or conclude by the use of the API does not amount to any statement or implication by us that we have determined or predicted future events or circumstances.
</p><p><strong>Inherent Risks</strong>
</p><p>You will take appropriate steps to prevent unauthorised persons from gaining access to the API through or using your login credentials or otherwise. You acknowledge that there are inherent risks associated with information transmission over the Internet and with the technical processes involved in such transmissions.
</p><p>We will use commercially reasonable efforts to make the API available, subject to the need from time to time to conduct scheduled and emergency maintenance. However we cannot guarantee this and we and are not liable for any error, suspension, interruption, or temporary unavailability of the API, and we are not responsible for any breach of security of the API, loss of data or breach of confidentiality or privacy rights arising from any unauthorised use of the API, or for damage due to viruses or other malicious code or malware.
</p><p><strong>Upgrades</strong>
</p><p>We are under no obligation to update or upgrade this website or the API for future changes in law, rule or regulation or for any other reason.
</p><p>We reserve the right to (but we are not obliged) maintain, tune, backup, amend, add or remove features, links or content at any time for any reason, redesign, improve or otherwise alter this website and the API without any notice to you.  We may install an upgrade at any time.
</p>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  created: function () {
    this.$stat.log({ page: 'about', action: 'open about' })
  }
}
</script>
