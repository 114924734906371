<template>
<b-container>
<h4>A. Order Form</h4>
<h4>1 KPMG</h4>
<p>KPMG LLP, a limited liability partnership registered in England and Wales (under registration number OC301540) having its registered office at 15 Canada Square, London E14 5GL.</p>
<h4>2 Licensee</h4>
<p>{{account.demo.licensee}}</p>
<h4>3 API</h4>
<p>KPMG Regulatory Horizon</p>
<h4>4 Commencement Date</h4>
<p>{{account.demo.commencementDate}}</p>
<h4>5 Initial Term </h4>
<p>{{account.demo.initialTerm}}</p>
<h4>6 Renewal Term</h4>
<p>Not applicable</p>
<h4>7 Authorised Users</h4>
<p>Licensee employees</p>
<h4>8 Authorised Purpose</h4>
<p>The API may be used for the Licensee internal business purposes.</p>
<h4>9 Fees</h4>
<p>NIL</p>
<h4>10 Technical Requirements</h4>
<p>NONE</p>
<h4>B. Terms of Use</h4>
<h4>1 Definitions</h4>
<p>“Acceptance Date” means the date you accept the API under clause 3.
</p><p>“Accept or Acceptance” means the earlier of your confirmation that the API conforms to the acceptance criteria set out in the Order Form or when you start using the API; 
</p><p>“Agreement” means the contract formed by the Order Form, and these Terms of Use. 
</p><p>“API” means the application programming interface in the Order Form, Regulatory Horizon including any changes, additions or modifications made to it at our sole discretion and Upgrades to it (if any).
</p><p>“Authorised Users” means those users of the API that are permitted to access the API via an HTTP protocol on your behalf and as may be further described in an applicable Purchase Order.
</p><p>“Commencement Date” means the date on which this Agreement will commence, as specified in the Order Form.
</p><p>“Confidential Information” means any information that has been or will be made available directly or indirectly by one Party to the other in connection with the API that is marked or communicated as confidential or whose nature is such that a recipient would reasonably consider it confidential, including all information embodied in the API, business plans, proposals, product development details, methodologies, API specifications, API code and financial information. Confidential Information excludes Excluded Information.
</p><p>“Control” means, in relation to a body corporate, the power to secure that its affairs are conducted in accordance with the wishes of the holding company, as defined in Section 1159 of the Companies Act 2006 and Controlled shall be construed accordingly.
</p><p>“Excluded Information” means information that: (a) is or becomes generally available in the public domain through no fault of either the receiving Party or those to whom the receiving Party has disclosed the Confidential Information; or (b) was previously known to the receiving Party free of any obligation of confidence; or (c) becomes available to the receiving Party free of any obligation of confidence from a third party entitled to make such disclosure; or (iv) was developed by a Party independently of the other Party’s Confidential Information. 
</p><p>“Fees” means the fees, charges and expenses set out in the Order Form.
</p><p>“Initial Term” means the initial period of 12 months for which the API is provided, or any other period as set out in the Order Form.
</p><p>“IPR” means all intellectual property rights including all rights in and to inventions, utility models, patents, copyright and related rights, trade marks, logos, trade and business names, rights in designs, rights in computer API, database rights, moral rights, rights in confidential information (including know-how and trade secrets) in every case whether registered or unregistered and all similar or equivalent rights or forms of protection (whether now or in the future) in any part of the world, and references to IPR means any of them.
</p><p>“KPMG” or “we” (and derivatives) means the entity identified as KPMG in the Order Form.
</p><p>“KPMG Persons” means KPMG and each and all of our members, employees, suppliers and agents together with any entity associated with us, and each and all of its partners, directors, employees and agents, and KPMG Person means any one of them.
</p><p>“Order Form” means the order for the licence for the API issued under and executed by the Parties in accordance with these Terms of Use.
</p><p>“Party” means either you or us (as appropriate) and Parties means both you and us. 
</p><p>“Renewal Term” means the period identified as such in the Order Form.
</p><p>“Technical Requirements” means the technical or other system requirements set out in the Order Form and as we may update and notify you from time to time. 
</p><p>“Term” means the period from the Commencement Date until termination or expiry of this Agreement.
</p><p>“Terms of Use” means these terms and conditions. 
</p><p>“Upgrade” means any upgrade, error correction or update to the API that we provide at our discretion from time to time.
</p><p>“You” (and derivatives) means the person named as “Licensee” in the Order Form.
</p><h4>2 Agreement</h4>
<p>2.1	These Terms of Use apply to the provision of the API and the Order Form. 
</p><p>2.2 This Agreement sets out the entire agreement between the Parties in connection with the API and extinguishes all previous agreements, promises, representations and understandings between the Parties, whether written or oral, to the extent that they relate to its subject matter.
</p><p>2.3 These Terms of Use do not apply to the provision of any other services or API that we may provide to you (whether provided concurrently with the API or otherwise). 
</p><p>2.4 The Parties acknowledge that, in entering into this Agreement, neither relies on any statement, representation, assurance or warranty (made innocently or negligently) that is not set out in this Agreement.	
</p><p>2.5 If there is any inconsistency between the documents that form this Agreement, the Order Form shall take precedence over the Terms of Use. 
</p><p>2.6 Any changes to any part of this Agreement must reference this Agreement, be in writing and be signed by the Parties.
</p><h4>3 Commencement and Duration</h4>
<p>3.1 This Agreement shall come into force on the Commencement Date for the Initial Term.
</p><p>3.2 Until you Accept the API you may not use the API other than for the purposes of Acceptance.
</p><h4>4 Licence </h4>
<p>4.1 Subject to clause 3.2 and your payment of the Fees, we grant to you a non-exclusive, non-sublicensable, non-transferable, revocable licence to you and your Authorised Users to access the API via an HTTP protocol for the Term. This licence shall only extend to use of the API that is for your own internal business purposes and any other purposes that are expressly set out in the applicable Order Form.
</p><p>4.2 You must ensure that all Authorised Users comply with the provisions of this Agreement and you are liable for any acts or omissions of all Authorised Users as if those were your own acts or omissions. 
</p><p>4.3 You acknowledge and agree that you shall not use the API as the sole means for keeping up to date with regulatory requirements and you shall remain responsible and accountable for managing your own affairs, deciding what to do after receiving the output of the API, whether to visit any third party websites or implement any recommendation.
</p><h4>5 Third-party websites</h4>
<p>5.1 You acknowledge that API may enable or assist you to access the website content of or correspond with third parties via third-party websites and that it does so solely at your own risk. 
</p><p>5.2 KPMG make no representation, warranty or commitment and shall have no liability or obligation whatsoever in relation to the content or use of, or correspondence with, any such third-party website.  We recommend that you refer to the third party's website terms and conditions and privacy policy prior to using the relevant third-party website.  
</p><p>5.3 We have no relationship, association or affiliation with any third-party websites.  We do not endorse or approve any third-party website nor the content of any of the third-party website made available via the API.
</p><h4>6 Your Obligations </h4>
<p>6.1 You must put in place and maintain adequate security measures to safeguard the API from unauthorised access, use or copying. You must notify us promptly of any potential or actual misuse of: 
</p><p>6.1.1 any user accounts or authentication credentials; and 
</p><p>6.1.2 any other security incident related to, or that may affect, the API.
</p><p>6.2	You are responsible for ensuring that your use of the API complies with any applicable legislation or other legal requirements.
</p><p>6.3	You are solely responsible for providing the means by which you access and use the API and for ensuring that any required access devices, networks and other items are in accordance with the Technical Requirements. 
</p><p>6.4	You are responsible for making any notifications, registrations and disclosures required of you by law or regulation.
</p><h4>7 Proprietary Rights and Restrictions</h4>
<p>7.1	The IPR in the API and any Upgrades, fixes and other and all related IPRs are our property or that of our third party licensors. Except for the licence granted to you under clause 4, you shall not acquire any right or interest of any kind in or to the that IPR.
</p><p>7.2	You will not, and you will procure that your Authorised Users do not: 
</p><p>7.2.1 other than as permitted by clause 4, license, sub-license, sell, resell, make available to third parties, profit from or commercially exploit, or make available for the benefit of any third party, all or any part of the API; 
</p><p>7.2.3 attempt to access areas of any portal or systems or network on which the API is hosted or via which the API is accessed, to which you have not been given access; 
</p><p>7.2.4 modify, disable, or compromise the integrity or performance of the API or any related systems, networks or data, including but not limited to probing, scanning or testing the vulnerability of any system or network that hosts the API; or
</p><p>7.2.5 overwhelm or attempt to overwhelm any infrastructure over which API is provided.
</p><p>7.3	You may not use our name, logo or trademarks in any marketing, promotional material or other publication except with our specific prior written consent.
</p><p>7.4	You shall notify us immediately if you become aware of any infringement of Intellectual Property Rights in or relating to the IPR licensed to it pursuant to this Agreement.
</p><h4>8 Charges</h4>
<p>8.1	Unless otherwise stated in the Order Form, we shall invoice you annually in advance for the Fees. You will pay our invoice within 30 days of the date of the invoice without any set-off. 
</p><p>8.2	We shall be entitled to payment of the Fees (on a pro-rata basis, if applicable) up to the date of termination if this Agreement is terminated.
</p><p>8.3	If any sums payable to us under this Agreement are in arrears for more than thirty (30) days after the due date, we reserve the right without prejudice to any other right or remedy to suspend your right to use the API without further notice.
</p><p>8.4	Unless otherwise stated in the Order Form, the Fees are exclusive of any applicable VAT.
</p><h4>9 Confidentiality</h4>
<p>9.1	The Parties shall each keep the other’s Confidential Information confidential and use it only for the purposes of using the API or for exercising their rights or performing their obligations under this Agreement. Each Party will protect Confidential Information of the other Party that it receives as it would protect its own Confidential Information, and in handling it, exercise at least a reasonable standard of care.
</p><p>9.2	We comply with the confidentiality standards of the Institute of Chartered Accountants in England and Wales (“ICAEW”) and with any obligations imposed by law or applicable regulators.
</p><p>9.3	Unless you tell us otherwise, we may disclose and discuss Confidential Information with your other advisers. The Parties may disclose Confidential Information if required by law or any applicable regulator but only to the extent required, and the Party intending to make such disclosure will provide the other with notice of the disclosure if permitted by law or regulation. The Parties may also disclose Confidential Information to their legal advisors and insurers in relation to any dispute arising under this Agreement.
</p><p>9.4	We will retain your Confidential Information in accordance with our record retention policy. We may share information relating to you and the API (including Confidential Information) with other KPMG Persons and our subcontractors providers and that information may be accessed by other parties who facilitate or support our business. We are still responsible for ensuring confidentiality if Confidential Information is shared with or accessed by such parties.
</p><h4>10 Warranties</h4>
<p>10.1 We do not warrant that operation of the API will be uninterrupted or error free.
</p><p>10.2 This Agreement sets out the full extent of our obligations and liabilities (and those of any of our subcontractors or other suppliers) concerning its subject matter. All other warranties, conditions, terms, undertakings and obligations that might otherwise be implied into this Agreement including any implied terms of satisfactory quality, ability to achieve a particular result and fitness for purpose are excluded to the fullest extent permitted by law.
</p><h4>11 Liability</h4>
<p>11.1 Subject to clauses 11.2 and 11.5, the respective aggregate liability (whether in contract, tort (including negligence), statutory liability or otherwise) of a Party to the other Party arising from or in connection with this Agreement in respect of all events occurring in each year from and including the Commencement Date and each anniversary thereof shall not exceed fifty thousand pounds (GBP 50,000). 
</p><p>11.2 Subject to clause 11.5 we will not be liable for any loss or damage arising out of or in connection with this Agreement, whether in contract, tort (including negligence) statutory liability or otherwise, to the extent that such loss or damage: 
</p><p>11.2.1 is indirect or consequential; or
</p><p>11.2.2 consists of loss of profits, goodwill, anticipated savings, business opportunity or wasted time;
</p><p>in each case, whether or not we have been advised of the possibility of such loss or damage.
</p><p>11.3 You shall not bring any claim against any KPMG Person other than us in respect of any loss, damage, liability or expenses arising out of or in connection with this Agreement. 
</p><p>11.4 Nothing in this Agreement shall exclude or limit your liability to us for any loss, damage, liability or reasonable expense that we suffer as a result of your breach of clauses 4.1.
</p><p>11.5 Nothing in this Agreement shall exclude or limit the liability of either Party for: 
</p><p>11.5.1 death or personal injury caused by the negligence of the other Party; 
</p><p>11.5.2 fraud or fraudulent misrepresentation; or
</p><p>11.5.3 any other liability that cannot be excluded or limited by law. 
</p><h4>12 Inherent Risks</h4>
<p>12.1 You will take appropriate steps to prevent unauthorised persons from gaining access to the API through or using your or your Authorised Users’ login credentials or otherwise. You acknowledge that there are inherent risks associated with information transmission over the Internet and with the technical processes involved in such transmissions. 
</p><p>12.2 Subject to the terms and conditions of the Agreement, we will use commercially reasonable efforts to make the API available, subject to the need from time to time to conduct scheduled and emergency maintenance. However we cannot guarantee this and we and are not liable for any error, suspension, interruption, or temporary unavailability of the API, and we are not responsible for any breach of security of the API, loss of data or breach of confidentiality or privacy rights arising from any unauthorised use of the API, or for damage due to viruses or other malicious code or malware.
</p><h4>13 Upgrades</h4>
<p>13.1 Unless specifically stated otherwise in the Order Form, we are under no obligation to update or upgrade the API for future changes in law, rule or regulation or for any other reason.
</p><p>13.2 We reserve the right to (but we are not obliged) maintain, tune, backup, amend, add or remove features, links or content at any time for any reason, redesign, improve or otherwise alter the API without any notice to you.  We may install an Upgrade at any time.
</p><h4>14 Termination</h4>
<p>14.1 Either Party may terminate this Agreement:
</p><p>14.1.1 at the end of the Initial Term or any Renewal Term by serving not less than thirty (30) days’ prior notice to the other Party; or
</p><p>14.1.2 immediately if the other Party is in material breach of any of its obligations under this Agreement and, (where the breach is capable of remedy), such breach is not remedied within thirty (30) days of that Party’s receipt of notice of such breach from the terminating Party.
</p><p>14.2 We may terminate this Agreement in whole or in part: 
</p><p>14.2.1 for convenience on 30 days’ notice; or 
</p><p>14.2.2 immediately if there is a change of law, rule, regulation or professional standard, or circumstances arise that KPMG reasonably believes would cause the relationship between the Parties to violate such law, rule, regulation or professional standard or would prejudice any KPMG Person’s ability to comply with applicable auditor independence requirements. 
</p><p>14.3 On termination of this Agreement you must immediately cease to use the API and we will deactivate or delete related user accounts (unless we are required by applicable law or professional standards to maintain such accounts) and you shall immediately take all necessary steps to cease further use of the API by your Authorised Users.
</p><p>14.4 Termination of this Agreement shall not affect the rights of either Party under this Agreement which may have accrued up to the date of termination, including in particular your obligation to make any payments due to us. Any provisions that are either expressly stated to survive termination or which, by implication, are intended to so survive.
</p><p>14.5 All amounts due but unpaid to us under this Agreement shall become payable immediately on its termination, and this provision is without prejudice to any right to claim for interest.
</p><p>14.6 If we terminate this Agreement under clause 14.2 or you terminate it under clause 14.1, we shall refund to you a pro-rata proportion of the Fees to account for the number of days of the agreed term remaining at the time of such termination. 
</p><h4>15 Force Majeure</h4>
<p>15.1 Neither Party shall be in breach of its obligations under this Agreement or incur any liability to the other if that Party is unable to comply with this Agreement because of any cause beyond its reasonable control.
</p><p>15.2 If such an event occurs, the affected Party shall, as soon as reasonably practical, notify the other, who may suspend or terminate this Agreement by giving fifteen (15) days’ written notice, taking effect if the affected Party has not recommenced the performance of its obligations in that period or such other period as the Parties have agreed in writing on each occasion.
</p><h4>16 Waiver and assignment</h4>
<p>16.1 Failure by either party to exercise or enforce any rights under this Agreement does not amount to a waiver of such rights.
</p><p>16.2 We may assign all or any of our rights or transfer all or any of our obligations under this Agreement. Except as expressly permitted by this Agreement, you shall not assign any of your rights or transfer any of your obligations under this Agreement without our prior written consent.
</p><p>16.3 We may appoint subcontractors to assist in the performance of our obligations under this Agreement but we shall: 
</p><p>16.3.1 remain responsible fulfilling our obligations under this Agreement; and 
</p><p>16.3.2 procure that they treat your Confidential Information under obligations equivalent to those in this Agreement.
</p><h4>17 Data Protection</h4>
<p>17.1 “DP Legislation” means the General Data Protection Regulation (EU 2016/679) and any legislation which amends, re-enacts or replaces it in England and Wales. The definitions and interpretations in the DP Legislation apply to this clause.
</p><p>17.2 The Parties acknowledge that no personal data shall be shared between them in connection with this Agreement with the exception of business contact details shared between their respective employees for the purpose of facilitating this Agreement (“Business Contact Details”). The Parties acknowledge that they shall each be independent controllers of any Business Contact Details they receive and agree to process such Business Contact Details in accordance with DP Legislation.
</p><h4>18 Notices</h4>
<p>18.1 Any notice given under this Agreement shall be in writing which includes email, except as set out in this clause. Any notice alleging breach of or terminating this Agreement must be delivered by first class registered post (or overseas equivalent) to, (or left and signed for at), the respective addresses of the Parties set out in the Order Form (or such other address as may be notified in writing) addressed to the lead persons of the relevant Party named in the Order Form and copied to each Parties’ respective General Counsel. 
</p><h4>19 Rights of Third Parties</h4>
<p>19.1 KPMG Persons (who are not the KPMG contracting Party) are entitled to the benefit of the rights given them under this Agreement and may exercise and enforce such rights pursuant to the Contracts (Rights of Third Parties) Act 1999.
</p><p>19.2 The parties may agree to rescind or vary this Agreement without anyone else’s consent, including KPMG Persons. 
</p><p>19.3 Except where this Agreement confers rights or benefits on KPMG Persons other than us, no one other than the Parties shall have any rights under the Contracts (Rights of Third Parties) Act 1999 or otherwise to enforce any part of this Agreement. 
</p><h4>20 Legal, Regulatory, Other compliance and Advice</h4>
<p>20.1 Each Party agrees that the other may make any notifications, registrations and disclosures required by applicable law or regulation or considered advisable and this may include disclosures or registrations relating to money laundering, tax requirements, and criminal or regulatory investigations. 
</p><p>20.2 You will comply with all our security, technology and risk management policies, rules and guidelines relating to the use of the API provided in writing or on-line to you from time to time, including but not limited to any privacy policy and any end user terms and conditions provided in connection with use of the API. 
</p><p>20.3 You are responsible for: 
</p><p>20.3.1 the management, conduct and operation of your business, interests and affairs; 
</p><p>20.3.2 compliance with your regulatory obligations; 
</p><p>20.3.3 deciding how you configure your systems to extract the data from the API; 
</p><p>20.3.4 deciding whether to visit third party websites; and 
</p><p>20.3.4 the delivery, achievement or realisation of any benefits directly or indirectly related to the API.
</p><p>20.4 Any opinion, statement of expectation, forecast or recommendation that you derive or conclude by the use of the API does not amount to any statement or implication by us that we have determined or predicted future events or circumstances. 
</p><h4>21 Disputes, law and jurisdiction</h4>
<p>21.1 If a dispute between the Parties cannot be resolved by commercial negotiation, the Parties shall attempt to resolve it in accordance with the Centre for Effective Dispute Resolution's (CEDR) model mediation procedure.
</p><p>21.2 If the Parties cannot agree on the joint appointment of a mediator within ten business days of initiating the mediation procedure (or any longer period agreed in writing), they shall make a joint request to CEDR to nominate the mediator.
</p><p>21.3 If the Parties reach agreement resolving the dispute, the agreement shall be put in writing and shall bind the Parties once it is signed by or on behalf of the Parties.
</p><p>21.4 Failing agreement, a Party may invite the mediator to provide a non-binding but informative opinion in writing.
</p><p>21.5 Any litigation in relation to the dispute may be commenced or continued notwithstanding the mediation unless the Parties agree otherwise or a court so orders.
</p><p>21.6 Each Party shall bear its own costs in relation to any mediation, and the fees and all other costs of the mediator shall be borne in equal proportions unless the mediator directs otherwise.
</p><p>21.7 Subject to the above process, this Agreement and all disputes arising on any basis from, under or in connection to it shall be governed by the laws of England and Wales and subject to the exclusive jurisdiction of the English courts.
</p><p>21.8 Referral of a dispute to mediation shall not prevent a Party from seeking an injunction or other equitable relief.
</p>
<h4>I have read and understood the terms and conditions of this Order Form and the attached Terms of Use and I agree to them.</h4>
</b-container>
</template>

<script>
export default {
  name: 'LegalAgreement',
  props: ['account']
}
</script>
<style scoped lang="scss">
h4 {
  font-size: 12px;
  font-weight: 800;
  margin-top: 5px;
  margin-bottom: 2px;
}
p {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
</style>