<template>
  <div class="container-fluid bg-light">
    <!-- REG HORIZON -->
    <div class="row text-white bg-kpmg-dark-blue py-4 mb-4">
      <div class="col">
        <h1 class="kstyle">Settings</h1>
      </div>
    </div>
    <b-row>
      <b-col>
        <b-container>
          <b-row class="bg-white pb-4" v-if="key">
            <b-col>
              <h5 class="kpmg">Your current data subscription</h5>
              <span>
              <div>key: {{account.key}}</div>
              <div>organisation: {{account.organisation}}</div>
              <div>comments: {{account.comments}}</div>
              <div>review date: {{account.expiry}}</div>
              <div><strong>Sources</strong></div>
              <ol>
                <li v-for="item in publishers" :key="item" class="mb-2">
                  <router-link :to="{ name: 'Regulator', params: { code: item } }" >{{item}}</router-link>
                </li>
              </ol>
              </span>
            </b-col>
          </b-row>
          <b-row class="bg-white pb-4" v-if="!loggedIn">
            <b-col>
              <h5 class="kpmg">New / change data subscription account</h5>
              <div class="mb-4">
                <b-form-input v-model="keyForm" placeholder="Enter your data subscription key" id="login"></b-form-input>
              </div>
              <div v-if="keyForm" class="mb-4">
                <b-button variant="outline-primary" @click="login" id="go">Login</b-button>
              </div>
              <div v-if="legalterms" class="mb-4">
                <b-button variant="outline-primary" @click="save" id="go">Update</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="bg-white pb-4" v-if="showTerms">
            <b-col>
              <h5 class="kpmg">Accept terms and conditions</h5>
              <div class="mb-4" >
                <div v-if="!account.demo">
                  <legal-simple></legal-simple>
                </div>
                <div v-if="account.demo">
                  <legal-agreement :account="account"></legal-agreement>
                </div>
                <b-button variant="outline-primary" class="mr-4" @click="agreeTerms" id="go">Accept</b-button>
                <b-button variant="outline-primary" @click="remove" id="go">Reject</b-button>
              </div>
            </b-col>
          </b-row>

          <b-row class="bg-white pb-4" v-if="showLogout" >
            <b-col>
              <h5 class="kpmg">Remove account key</h5>
              <span>
              <div>
                <b-button variant="outline-primary" @click="remove">Log out</b-button>
              </div>
              </span>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import LegalAgreement from '@/components/LegalAgreement.vue'
import LegalSimple from '@/components/LegalSimple.vue'

export default {
  name: 'Settings',
  components: {
    LegalAgreement,
    LegalSimple
  },
  computed: {
    showLogout: function () {
      return this.loggedIn && this.key
    },
    showTerms: function () {
      return this.loggedIn && !this.key
    },
    key: {
      get () {
        return this.$store.state.key
      },
      set (payload) {
        this.$store.commit('setKey', payload)
      }
    },
    publishers: function () {
      let publishers = this.account.publishers
      publishers.sort()
      return publishers
    }
  },
  created: async function () {
    const key = window.localStorage.getItem('frmCloudExApiKey')
    if (!key) {
      return
    } else {
      this.$store.commit('setKey', key)
    }
    try {
      let apiName = 'cosmos'
      let path = `/account`
      let response = await this.$Amplify.API.get(apiName, path)
      this.account = response
      this.loggedIn = true
    } catch (e) {
      this.$logger.warn('getCategories loading error' + e)
    }
  },
  data () {
    return {
      account: false,
      keyForm: null,
      legalterms: false,
      loggedIn: false
    }
  },
  methods: {
    agreeTerms: async function () {
      window.localStorage.setItem('frmCloudExApiKey', this.keyForm)
      try {
        const apiName = 'cosmos'
        const path = `/account`
        const response = await this.$Amplify.API.get(apiName, path)
        this.account = response
        if (this.account.key === this.keyForm) {
          window.localStorage.setItem('frmCloudExUsername', this.account.organisation)
          if (!this.account.acgroups) {
            this.account.acgroups = ['norole']
          }
          window.localStorage.setItem('frmCloudExApiKey', this.keyForm)
          window.localStorage.setItem('frmCloudExAcgroups', JSON.stringify(this.account.acgroups))
          window.localStorage.setItem('frmCloudExUsername', this.account.organisation)
          const user = { acgroups: this.account.acgroups }
          this.$store.commit('setKey', this.keyForm)
          this.$store.commit('setUsername', this.account.organisation)
          this.$store.commit('setUser', user)
          this.$stat.log({ page: 'settings', action: 'logged in' })
        }
      } catch (e) {
        this.$logger.warn('getCategories loading error' + e)
      }
    },
    login: async function () {
      // window.localStorage.setItem('frmCloudExApiKey', this.keyForm)
      try {
        const apiName = 'cosmos'
        const path = `/account`
        const init = {
          headers: {
            'X-API-KEY': this.keyForm
          }
        }
        let response = await this.$Amplify.API.get(apiName, path, init)
        if (response.key === this.keyForm) {
          this.account = response
          this.loggedIn = true
        }
      } catch (e) {
        this.$logger.warn('getCategories loading error' + e)
      }
    },
    remove: function () {
      this.$stat.log({ page: 'settings', action: 'logged out' })
      window.localStorage.removeItem('frmCloudExApiKey')
      window.localStorage.removeItem('frmCloudExUsername')
      this.account = false
      this.$store.commit('setKey', null)
      this.$store.commit('setUsername', null)
      this.loggedIn = false
    },
    save: async function () {
      window.localStorage.setItem('frmCloudExApiKey', this.keyForm)
      try {
        const apiName = 'cosmos'
        const path = `/account`
        const response = await this.$Amplify.API.get(apiName, path)
        this.account = response
        if (this.account.key === this.keyForm) {
          window.localStorage.setItem('frmCloudExUsername', this.account.organisation)
          if (!this.account.acgroups) {
            this.account.acgroups = ['norole']
          }
          window.localStorage.setItem('frmCloudExAcgroups', JSON.stringify(this.account.acgroups))
          window.localStorage.setItem('frmCloudExUsername', this.account.organisation)
          const user = { acgroups: this.account.acgroups }
          this.$store.commit('setKey', this.keyForm)
          this.$store.commit('setUsername', this.account.organisation)
          this.$store.commit('setUser', user)
          this.$stat.log({ page: 'settings', action: 'logged in' })
        }
      } catch (e) {
        this.$logger.warn('getCategories loading error' + e)
      }
    }
  }
}
</script>
